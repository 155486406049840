<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <div class="d-flex">
          <feather-icon icon="FolderIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">General Information</h5>
        </div>
        <b-row class="mt-1 mb-1">
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Name" rules="required">
              <b-form-group label="Name">
                <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Price">
              <b-form-group label="Price">
                <b-form-input trim placeholder="Price" v-model="dataInfo.price" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Square Footage">
              <b-form-group label="Square Footage">
                <b-form-input trim placeholder="Square Footage" v-model="dataInfo.square_footage" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Project Url">
              <b-form-group label="Project Url">
                <b-form-input trim placeholder="Project Url" v-model="dataInfo.project_url" :state="getValidationState(validationContext)" id="project_url"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Project Style">
              <b-form-group label="Project Style" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.project_style_id" :options="projectStyleOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Status" rules="required">
              <b-form-group label="Status" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <validation-provider #default="validationContext" name="Customer Name">
              <b-form-group label="Customer Name">
                <b-form-input trim placeholder="Customer Name" v-model="dataInfo.customer_name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="d-flex">
          <feather-icon icon="MapPinIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Addres Information</h5>
        </div>
        <b-row class="mt-1 mb-1">
          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="City Town">
              <b-form-group label="City Town">
                <b-form-input trim placeholder="City Town" v-model="dataInfo.city_town" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="State">
              <b-form-group label="State" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.states_id" :options="stateOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Position Latitude">
              <b-form-group label="Position Latitude">
                <b-form-input trim placeholder="Position Latitude" v-model="dataInfo.position_lat" :state="getValidationState(validationContext)" id="position_lat"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Position Longitude">
              <b-form-group label="Position Longitude">
                <b-form-input trim placeholder="Position Longitude" v-model="dataInfo.position_long" :state="getValidationState(validationContext)" id="position_long"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="d-flex">
          <feather-icon icon="InfoIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Other Information</h5>
        </div>
        <b-row class="mt-1 mb-1">
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Spaces">
              <b-form-group label="Spaces" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.spaces_id" :options="spaceOptions" :reduce="val => val.value" :clearable="false" multiple="multiple"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Floor Materials">
              <b-form-group label="Floor Materials" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.floor_material_id" :options="floorMaterialOptions" :reduce="val => val.value" :clearable="false" multiple="multiple"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Features">
              <b-form-group label="Features" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.features_id" :options="featureOptions" :reduce="val => val.value" :clearable="false" multiple="multiple"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Image">
              <b-media no-body>
                <b-media-aside>
                  <b-img rounded :src="dataInfo.imageBase64 ? dataInfo.imageBase64 : getApiFile(dataInfo.image_url, true)" height="80"/>
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="selectFile('image_file_project')">Upload</b-button>
                  <b-form-file
                      plain
                      :id="'image_file_project'"
                      :name="'image_file_project'"
                      :hidden="true"
                      accept=".jpg, .jpeg, .png, .gif"
                      @input="inputImageRenderer('image_file_project')"/>
                  <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="resetFile()">Reset</b-button>
                  <b-card-text>Allowed JPG, GIF or PNG.</b-card-text>
                </b-media-body>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Description">
              <b-form-group label="Description">
                <Editor v-model="dataInfo.description" api-key="cs0g571gu5ikcm2btrlcydjf5wl02m01cp42lpffwjs3o90j" :init="editorConfig"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <action-buttons :back-route="'cms-project-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab, BFormTextarea, BMedia, BMediaBody, BFormFile, BMediaAside, BLink, BImg, BCardText,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/cms/project/store"
import router from '@/router'
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions} from "@core/utils/filter"
import {toastMessage} from "@core/utils/utils"
import {useInputIdImageRenderer} from "@core/comp-functions/forms/form-utils"
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    BCardText,
    BImg,
    BLink,
    BMediaAside,
    BFormFile,
    BMediaBody,
    BMedia,
    BFormTextarea,
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,

    Editor,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      name: '',
      position_lat: '',
      position_long: '',
      imageBase64: null,
      image_url: '',
      project_url: '',
      description: '',
      price: null,
      square_footage: null,
      project_style_id: null,
      city_town: '',
      states_id: null,
      spaces_id: null,
      floor_material_id: null,
      features_id: null,
      customer_name: '',
      status: 1
    })

    const selectFile = (input) => {
      document.getElementById(input).click()
    }

    const resetFile = () => {
      dataInfo.value.image_url = null
      dataInfo.value.imageBase64 = null
    }

    const inputImageRenderer = (input) => {
      useInputIdImageRenderer(input, base64 => {
        dataInfo.value.imageBase64 = base64
        document.getElementById("position_lat").focus();
        document.getElementById("position_long").focus();
        document.getElementById("project_url").focus();
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'cms-project-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const projectStyleOptions = ref([])
    busy.value = true
    store.dispatch('store/projectStyleItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          projectStyleOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const spaceOptions = ref([])
    busy.value = true
    store.dispatch('store/spaceItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          spaceOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const featureOptions = ref([])
    busy.value = true
    store.dispatch('store/featureItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          featureOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const floorMaterialOptions = ref([])
    busy.value = true
    store.dispatch('store/floorMaterialItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          floorMaterialOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const stateOptions = ref([])
    busy.value = true
    store.dispatch('store/statesItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          stateOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'cms-project-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id > 0 ? router.currentRoute.params.id : 0}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
        if (error.response.status === 403) {
          router.push({name: 'cms-project-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const editorConfig = ref({
      branding: false,
      selector: 'textarea',
      height: '400px',
      verify_html: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      editorConfig,
      spaceOptions,
      projectStyleOptions,
      floorMaterialOptions,
      stateOptions,
      featureOptions,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
      selectFile,
      resetFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.tox-tinymce {
  height: 300px;
}

</style>
